<template>
  <div class="taskallocation bgwhite" v-loading="loading2">
    <!-- 任务分配 -->
    <div class="flex_between bbe9s">
      <div class="title f-left p10 ml10">任务分配</div>
      <el-button
        type="primary"
        plain
        style="margin: 5px"
        @click="openDistribute"
        >自动分配</el-button
      >
    </div>
    <div class="t1 mt10 bgwhite plr20">
      <div class="block flex_column">
        <div class="flex_between mt20 bbe9s pb20">
          <div
            class="flex_column txt_center"
            @click="chakan(false, 'totalTask')"
          >
            <p class="f18">今日任务总量</p>
            <p class="f26 mt10">{{ countz }}</p>
          </div>
          <div
            class="flex_column txt_center"
            @click="chakan(false, 'unallocatedTask')"
          >
            <p class="f18">今日未分配任务</p>
            <p class="f26 mt10">{{ count3 }}</p>
          </div>
          <div
            class="flex_column txt_center"
            @click="chakan(false, 'allocationTask')"
          >
            <p class="f18">今日已分配任务</p>
            <p class="f26 mt10">{{ count2 }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 任务列表 -->
    <div class="t1 mt10">
      <div class="block flex_column">
        <!-- <div class="title f-center p10">任务列表</div> -->
        <div class="mt10">
          <el-table
            :data="tableData"
            ref="multipleTable"
            @selection-change="handleSelectionChange"
            border
          >
            <el-table-column
              type="selection"
              width="60"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="username"
              label="客服"
              align="center"
            ></el-table-column>
            <el-table-column prop="contacts" label="权限" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.administrators == 0">客服</span>
                <span v-else>管理员</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="mobile"
              label="电话"
              align="center"
            ></el-table-column>
            <el-table-column
              label="总分配任务"
              prop="distribution"
              align="center"
            ></el-table-column>
            <el-table-column
              label="今日已分配任务"
              prop="distributions"
              align="center"
            ></el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  type="success"
                  size="small"
                  plain
                  class="m-t-5 mr5"
                  @click="fen(scope.row, 'fen')"
                  >分配任务</el-button
                >
                <el-button
                  type="danger"
                  size="small"
                  plain
                  class="m-t-5 mr5"
                  @click="fen(scope.row, 'qing')"
                  style="margin-left: 0"
                  >清空任务</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!-- 均分 -->
    <!-- <div class="flex_start p20 flex_items bgwhite">
      <el-button type="primary" size="medium" plain @click="junfen" style="margin: 20px auto"
        >平均分配到选中客服</el-button
      >
    </div> -->
    <!-- 弹框 -->
    <div v-if="fenpeirenwu">
      <el-dialog :visible.sync="fenpeirenwu" width="80%" center>
        <div class="flex_warp mt20 pb30" style="overflow: inherit !important">
          <!-- 城市筛选 -->
          <div class="blocks2 flex_start flex_items">
            <span class="mr20">城市筛选</span>
            <el-select
              v-model="citykind"
              placeholder="选择城市"
              :clearable="true"
              style="width: 220px"
              @change="shop()"
            >
              <el-option
                v-for="(item, index) in citys"
                :key="index"
                :label="item.city"
                :value="item.city_id"
              ></el-option>
            </el-select>
          </div>
          <!-- 订单状态 -->
          <div class="blocks2 flex_start flex_items">
            <span class="mr20">订单状态</span>
            <el-select
              v-model="orderzhuang"
              :clearable="true"
              placeholder="选择订单状态"
              style="width: 220px"
            >
              <el-option
                v-for="item in orderzhuangs"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <!-- 录入人员 -->
          <div class="blocks2 flex_start flex_items">
            <span class="mr20">录入人员</span>
            <el-select
              class="mr10"
              filterable
              v-model="isok.entry"
              collapse-tags
              placeholder="选择录入人员"
              :clearable="true"
            >
              <el-option
                v-for="item in tableData"
                :key="item.admin_id"
                :label="item.username"
                :value="item.username"
              ></el-option>
            </el-select>
          </div>
          <!-- 任务类型 -->
          <div class="blocks2 flex_start flex_items">
            <span class="mr20">任务类型</span>
            <el-select
              v-model="taskid"
              placeholder="选择任务类型"
              :clearable="true"
              style="width: 220px"
            >
              <el-option
                v-for="item in tasks"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <!-- 店长筛选 -->
          <div v-if="citykind != ''" class="blocks2 flex_start flex_items">
            <span class="mr20">选择店长</span>
            <el-select
              class="mr10"
              filterable
              v-model="isok.shopper"
              collapse-tags
              placeholder="选择店长"
              :clearable="true"
            >
              <el-option
                v-for="item in shoppers"
                :key="item.user_id"
                :label="item.usernamestorename"
                :value="item.usernamestorename"
              ></el-option>
            </el-select>
          </div>
          <!-- 筛选 -->
          <div class="blocks2 flex_start flex_items">
            <el-button
              type="primary"
              size="small"
              plain
              style="margin-right: 30px"
              @click="shaixuan"
              >筛选</el-button
            >
          </div>
        </div>
        <div>
          <el-table
            :data="tableData2"
            ref="multipleTable2"
            @selection-change="handleSelectionChange2"
            border
            v-loading="loading"
          >
            <el-table-column
              type="selection"
              width="60"
              align="center"
            ></el-table-column>
            <el-table-column prop="cc_id" label="序号"></el-table-column>
            <el-table-column prop="order_no" label="订单号"></el-table-column>
            <el-table-column prop="entry" label="录入人员"></el-table-column>
            <el-table-column label="任务类型">
              <template slot-scope="scope">
                <span v-if="scope.row.type == 0">指派任务</span>
                <span v-if="scope.row.type == 1">超时未接单</span>
                <span v-if="scope.row.type == 2">打卡未报价</span>
                <span v-if="scope.row.type == 3">施工超期</span>
                <span v-if="scope.row.type == 4">完工回访</span>
                <span v-if="scope.row.type == 5">差评与投诉</span>
              </template>
            </el-table-column>
            <el-table-column prop="contacts" label="联系人"></el-table-column>
            <el-table-column
              prop="telephone"
              label="联系方式"
            ></el-table-column>
            <el-table-column prop="addres" label="维修地址"></el-table-column>
            <el-table-column prop="states" label="订单状态"></el-table-column>
            <el-table-column prop="store_name" label="店铺"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  type="warning"
                  size="small"
                  plain
                  @click="chaxiang(scope.row)"
                  class="mt10 ml10"
                  >查看</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="flex_start mt20 flex_items">
            <span class="demonstration mr20">
              总共
              <span style="color: red">{{ count }}</span
              >条数据,当前
              <span style="color: red">{{ tableData2.length }}</span
              >条
            </span>
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage3"
              :page-size="10"
              layout="prev, pager, next, jumper"
              :total="count"
            ></el-pagination>
          </div>
          <div
            class="flex_start p20 flex_items bgwhite"
            style="padding-left: 60px"
          >
            <el-button
              v-if="kuang.fenfen == 'fen'"
              type="primary"
              size="small"
              plain
              @click="fenpeikefu"
              style="margin: 0 auto"
              >分配选中任务</el-button
            >
            <el-button
              v-else-if="kuang.fenfen == 'qing'"
              type="danger"
              size="small"
              plain
              @click="fenpeikefu2"
              style="margin: 0 auto"
              >清空选中任务</el-button
            >
          </div>
        </div>
      </el-dialog>
    </div>
    <!-- 分配任务弹框 -->
    <div v-if="givework">
      <el-dialog :visible.sync="givework" center>
        <div class="bbe9s pb20 f20">
          分配任务
          <span class="f16">(共{{ countz3 }}个)</span>
        </div>
        <div class="mt20 flex_warp">
          <div class="demo-input-suffix flex_wrap" style="width: 100%">
            <div
              v-for="(item, index) in workarr"
              :key="index"
              style="display: inline-block"
              class="mr20 mt15"
            >
              <span style="width: 72px; display: inline-block"
                >{{ item.name }}：</span
              >
              <el-input
                placeholder="请输入任务数量"
                v-model="item.count"
                :value="item.count"
              ></el-input>
            </div>
          </div>
        </div>
        <div class="mt20" style="text-align: center">
          <el-button type="success" size="small" plain @click="startwork"
            >立即分配</el-button
          >
        </div>
      </el-dialog>
    </div>
    <!-- 自动分配模态框 -->
    <el-dialog
      title="自动分配任务"
      :visible.sync="dialogVisibleFen"
      width="30%"
    >
      <span>确定要分配所有任务吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleFen = false">取 消</el-button>
        <el-button type="primary" @click="sureDistribute">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 详情弹框 -->
    <div v-if="isdiag">
      <el-dialog
        :visible.sync="dialogTableVisible"
        @open="diagopen()"
        @close="diagclose()"
        width="70%"
      >
        <Detailes2></Detailes2>
      </el-dialog>
    </div>
    <!-- 任务分配标题弹框 -->
    <!-- <div v-if="isdiagModal"> -->
    <el-dialog
      :visible.sync="dialogTableVisible2"
      @open="diagopen()"
      append-to-body
      @close="diagclose()"
      width="80%"
    >
      <totalTask v-if="dialogname == 'totalTask'"></totalTask>
      <unallocatedTask v-if="dialogname == 'unallocatedTask'"></unallocatedTask>
      <allocationTask v-if="dialogname == 'allocationTask'"></allocationTask>
    </el-dialog>
    <!-- </div> -->
  </div>
</template>
<script>
import detailes2 from "@/components/detailes2.vue";
import totalTask from "@/components/totalTask.vue";
import unallocatedTask from "@/components/unallocatedTask.vue";
import allocationTask from "@/components/allocationTask.vue";
import config from "@/util/config";
export default {
  data() {
    return {
      loading2: false,
      loading: true,
      dialogTableVisible: false,
      dialogTableVisible2: false,
      dialogVisibleFen: false,
      isdiag: false,
      isdiagModal: false,
      tableData: [],
      tableData2: [],
      art: [],
      kaishi: 0,
      multipleSelection: [],
      multipleSelection2: [],
      fenpeirenwu: false,
      // 分配筛选
      //  订单状态
      orderzhuangs: config.orderzhuangs,
      tasks: [],
      citys: [],
      citykind: "",
      taskid: "",
      orderzhuang: "",
      isok: {
        shopper: "",
        entry: "",
      },
      shoppers: [],
      // 分页数据
      currentPage3: 1,
      tableData: [],
      count: 0,
      kuang: {
        admin_id: "",
        fenfen: "",
      },
      countz: 0,
      countz1: 0,
      countz2: 0,
      countz3: 0,
      count1: 0,
      count2: 0,
      count3: 0,
      givework: false,
      workarr: [],
      dialogname: "totalTask",
    };
  },
  mounted() {
    this.orderzhuang = "";
    this.citykind = "";
    this.getLists();
  },
  methods: {
    openDistribute() {
      this.dialogVisibleFen = true;
    },
    sureDistribute() {
      this.loading2 = true;
      this.util.get(this.HOST + "/Cc/ziDong", {}).then((res) => {
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: "所有任务分配成功！",
            type: "success",
            duration: 1200,
            offset: 90,
          });
          this.loading2 = false;
          this.dialogVisibleFen = false;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
    // 查看任务
    chakan(row, con) {
      this.dialogname = con;
      this.dialogTableVisible2 = true;
      if (row) {
        this.$store.commit("setOrder", row);
      }
      this.$store.commit("setMessage", true);
    },
    // 列表
    getLists(all) {
      if (!all) {
        this.$loading(this.util.opload);
      }
      this.util.get(this.HOST + "/User/user", {}).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data;
          this.getfenpei();
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
    getfenpei() {
      this.util.fenpei(this.HOST + "/Cc/AllocationRule", {}).then((res) => {
        if (res.code == 200) {
          this.countz = res.data.count;
          this.countz2 = res.data.count3;
          this.countz3 = res.data.count2;
          this.count2 = res.data.count2;
          this.count3 = res.data.count3;
          this.$loading().close();
          this.loading = false;
        } else {
          this.loading = false;
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
    choseall() {
      if (this.allchose == false) {
        this.getLists();
      } else {
        this.getLists("true");
      }
    },
    chaxiang(row) {
      this.$store.commit("setOrder", row);
      this.$store.commit("setMessage", true);
    },
    diagopen() {
      this.$store.commit("setMessage", true);
    },
    diagclose() {
      this.$store.commit("setMessage", false);
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSelectionChange2(val) {
      if (this.kaishi == 1) {
        return false;
      } else {
        if (this.currentPage3 == 1) {
          this.art[0] = [];
          this.art[this.currentPage3] = val;
        } else {
          this.art[this.currentPage3] = val;
          for (let i = 0; i < this.art.length; i++) {
            if (!this.art[i]) {
              this.art[i] = [];
            }
          }
        }
      }
    },
    junfen() {
      let arr = [];
      if (this.multipleSelection.length == 0) {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择至少一位客服!",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      // 开始均分
      let total = this.countz3;
      let count = this.multipleSelection.length;

      let base = Math.floor(total / count);
      let rest = total % count;

      let arr2 = []; //arr2是均分出来的任务数据
      let arr3 = []; //arr3是最后合成数组
      for (let i = 0; i < count; i++) {
        arr2.push(base + (i < rest ? 1 : 0));
      }
      for (let i = 0; i < this.multipleSelection.length; i++) {
        arr3.push({
          name: this.multipleSelection[i].username,
          user: this.multipleSelection[i].admin_id,
          count: arr2[i],
        });
      }
      this.workarr = arr3;
      this.givework = true; // 平均分配
    },
    startwork() {
      let arr = [];
      let zongshu = 0;
      for (let i = 0; i < this.workarr.length; i++) {
        zongshu += Number(this.workarr[i].count);
        arr.push({
          user: this.workarr[i].user,
          count: Number(this.workarr[i].count),
        });
      }
      if (zongshu > this.countz3) {
        this.$message({
          showClose: true,
          type: "error",
          message: "分配任务大于总数!",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      if (zongshu == 0) {
        this.$message({
          showClose: true,
          type: "error",
          message: "请至少分配一条任务!",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      this.qufenpei(arr);
    },
    qufenpei(arr) {
      this.$loading({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.7)",
        text: "正在努力分配中...",
      });
      this.util
        .post(this.HOST + "/User/distribution", {
          arr,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: "分配完成!",
              type: "success",
              duration: 1200,
              offset: 90,
            });
            setTimeout(() => {
              this.givework = false;
              this.tableData = [];
              this.getLists();
            }, 1000);
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.msg,
              duration: 1200,
              offset: 90,
            });
            this.$loading().close();
            this.loading = false;
          }
        });
    },
    fen(idx, fenfen) {
      this.getcitys(idx.admin_id, fenfen);
      this.taskid = "";
      this.kuang.admin_id = idx.admin_id;
      this.kuang.fenfen = fenfen;
      this.multipleSelection2 = [];
      this.currentPage3 = 1;
      this.citykind = "";
      this.orderzhuang = "";
      this.loading = true;
      this.fenpeirenwu = true;
    },
    // 城市
    getcitys(admin_id, fenfen) {
      this.util.get(this.HOST + "/Common/cheng", {}).then((res) => {
        if (res.code == 200) {
          this.citys = res.data;
          this.gettasks(admin_id, fenfen);
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
    // 任务类型
    gettasks(admin_id, fenfen) {
      this.util.get(this.HOST + "/Common/service", {}).then((res) => {
        if (res.code == 200) {
          this.tasks = res.data;
          // this.shop(admin_id, fenfen);
          this.getall(admin_id, fenfen);
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
    // 获取店长
    shop() {
      let citykind = 241;
      if (this.citykind != "") {
        citykind = this.citykind;
      }
      this.util
        .get(this.HOST + "/Common/user", {
          city_id: citykind,
        })
        .then((res) => {
          if (res.code == 200) {
            this.shoppers = res.data;
            // this.getall(admin_id, fenfen);
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 弹框客服任务列表
    getall(admin_id, fenfen) {
      let admin_id2 = 0;
      let types = 9;
      let SelectedStatus = 1;
      if (fenfen == "qing") {
        admin_id2 = admin_id;
        types = 3;
        SelectedStatus = 2;
      }
      this.util
        .get(this.HOST + "/User/ccList", {
          page: this.currentPage3,
          complaints: this.taskid,
          admin_id: admin_id2,
          city_id: this.citykind,
          type: types,
          limit: 10,
          types: 0,
          entry: this.isok.entry,
          assignor: this.isok.shopper,
          SelectedStatus: SelectedStatus,
        })
        .then((res) => {
          if (res.code == 200) {
            this.count = res.data.count;
            this.tableData2 = res.data.data;
            this.loading = false;
          } else {
            this.loading = false;
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    shaixuan() {
      this.loading = true;
      this.tableData2 = [];
      this.currentPage3 = 1;
      let admin_id2 = 0;
      let types = 0;
      let SelectedStatus = 1;
      if (this.kuang.fenfen == "qing") {
        admin_id2 = this.kuang.admin_id;
        types = 3;
        SelectedStatus = 2;
      }
      this.util
        .get(this.HOST + "/User/ccList", {
          admin_id: admin_id2,
          complaints: this.taskid,
          city_id: this.citykind,
          status: this.orderzhuang,
          page: 1,
          types: 0,
          limit: 10,
          type: types,
          assignor: this.isok.shopper,
          entry: this.isok.entry,
          SelectedStatus: SelectedStatus,
        })
        .then((res) => {
          if (res.code == 200) {
            this.count = res.data.count;
            this.tableData2 = res.data.data;
            this.loading = false;
          } else {
            this.loading = false;
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 分页
    handleCurrentChange(val) {
      this.loading = true;
      this.kaishi = 1;
      this.tableData2 = [];
      let admin_id2 = 0;
      let types = 0;
      let SelectedStatus = 1;
      if (this.kuang.fenfen == "qing") {
        admin_id2 = this.kuang.admin_id;
        types = 3;
        SelectedStatus = 2;
      }
      this.util
        .get(this.HOST + "/User/ccList", {
          admin_id: admin_id2,
          complaints: this.taskid,
          page: this.currentPage3,
          city_id: this.citykind,
          status: this.orderzhuang,
          types: 0,
          limit: 10,
          type: types,
          entry: this.isok.entry,
          assignor: this.isok.shopper,
          SelectedStatus: SelectedStatus,
        })
        .then((res) => {
          if (res.code == 200) {
            this.count = res.data.count;
            this.tableData2 = res.data.data;
            // 默认选中
            if (!this.art[this.currentPage3]) {
              this.art[this.currentPage3] = [];
            }
            if (this.art[this.currentPage3].length != 0) {
              for (let i = 0; i < this.tableData2.length; i++) {
                for (let j = 0; j < this.art[this.currentPage3].length; j++) {
                  if (
                    this.tableData2[i].cc_id ==
                    this.art[this.currentPage3][j].cc_id
                  ) {
                    this.xuanzhong([this.tableData2[i]]);
                  }
                }
              }
              this.kaishi = 0;
            } else {
              this.loading = false;
              this.kaishi = 0;
            }
          } else {
            this.loading = false;
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    xuanzhong(rows) {
      this.$nextTick(() => {
        rows.forEach((row) => {
          this.$refs.multipleTable2.toggleRowSelection(row, true);
        });
        this.loading = false;
      });
    },
    fenpeikefu() {
      let arr = [];
      let arr2 = {};
      let count = 0;
      // 判断empty
      for (let i = 0; i < this.art.length; i++) {
        if (!this.art[i]) {
          this.art[i] = [];
        }
        // 判断结束
        count += this.art[i].length;
        for (let j = 0; j < this.art[i].length; j++) {
          arr.push(this.art[i][j].cc_id);
        }
      }
      if (count == 0) {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择至少一项任务!",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      arr2 = {
        user: this.kuang.admin_id,
        data: arr,
      };
      this.$loading({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.7)",
        text: "正在努力分配中...",
      });
      this.util
        .post(this.HOST + "/User/ManualDistribution", { arr2 })
        .then((res) => {
          if (res.code == 200) {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: "分配完成!",
              type: "success",
              duration: 1200,
              offset: 90,
            });
            this.fenpeirenwu = false;
            setTimeout(() => {
              this.tableData = [];
              this.getLists();
            }, 1000);
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    fenpeikefu2() {
      let arr = [];
      let arr2 = {};
      let count = 0;

      for (let i = 1; i < this.art.length; i++) {
        if (!this.art[i]) {
          this.art[i] = [];
        }
        count += this.art[i].length;
        for (let j = 0; j < this.art[i].length; j++) {
          arr.push(this.art[i][j].cc_id);
        }
      }
      if (count == 0) {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择至少一项任务!",
          duration: 1200,
          offset: 90,
        });
        0;
        return false;
      }
      arr2 = {
        user: this.kuang.admin_id,
        data: arr,
      };
      this.$loading({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.7)",
        text: "正在删除中...",
      });
      this.util.post(this.HOST + "/User/emptyMission", { arr2 }).then((res) => {
        if (res.code == 200) {
          this.$loading().close();
          this.$message({
            showClose: true,
            message: "删除完成!",
            type: "success",
            duration: 1200,
            offset: 90,
          });
          this.fenpeirenwu = false;
          setTimeout(() => {
            this.tableData = [];
            this.getLists();
          }, 1000);
        } else {
          this.$loading().close();
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
  },
  components: {
    Detailes2: detailes2,
    totalTask: totalTask,
    unallocatedTask: unallocatedTask,
    allocationTask: allocationTask,
  },
  computed: {
    getcount() {
      this.countz1 = this.$store.state.works;
    },
    // 判断弹出框
    getStoreItem() {
      this.dialogTableVisible = this.$store.state.dialogTableVisible;
      this.isdiag = this.$store.state.dialogTableVisible;
    },
  },
  watch: {
    getcount() {
      //console.log(this.$store.state.works, "121212");
    },
    getStoreItem() {
      //console.log(this.$store.state.dialogTableVisible, "121212");
    },
  },
};
</script>
<style>
.taskallocation {
  padding-left: 20px;
}
.el-checkbox__inner {
  width: 20px;
  height: 20px;
}
.el-checkbox__inner::after {
  height: 9px;
  left: 6px;
  top: 3px;
  width: 5px;
}
</style>
